<template>
  <v-breadcrumbs
    class="ml-n1 pa-0 text-medium-emphasis text-truncate d-block mb-2"
    density="compact"
    :items="displayedItems"
  >
    <template v-slot:divider>
      <v-icon :icon="mdiChevronRight"></v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script setup>
import { mdiChevronRight } from '@mdi/js'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
})

const displayedItems = computed(() => {
  // If on mobile, show only the last two items
  if (mobile.value) {
    return props.items.slice(-2)
  }

  return props.items
})
</script>